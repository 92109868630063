import {
  CircularProgress,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'

import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import {
  checkPasswordVerification,
  getAssetJsonUrl,
  getAssetUrl,
  getThemeAssetUrl,
} from '../../utils'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useNavigate } from 'react-router-dom'
import { DropDownGlobal } from '../../pages/landing/component/dropdown/DropDown'
import { useDispatch, useSelector } from 'react-redux'

import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { toast } from 'react-toastify'
import {
  DEFAULT_WEBSITE_NAME,
  HEADERS,
  makePostHttpCallAsync,
  OTP_RESEND_TIME,
  REGISTER_SCREEN_UPDATE,
} from '../../api'
import { IThemeConfiguration } from '../../types/interfaces/themeConfiguration'
import { RenderReverseNumber } from './RenderReverseNumber'

export interface IRegister {
  setIsRegister: (isLogin: boolean) => void
  setIsLoginScren: (isLogin: boolean) => void
  setIsTermsAndConditions: (isLogin: boolean) => void
  userSignUp?: (
    payload: any,
    navigate: any,
    setIsRegister: (isOpen: boolean) => void,
    currentDomain: ICurrentDomain,
    cb: any,
  ) => void
  appThemeConfiguration?: IThemeConfiguration
}

export const Register = forwardRef((props: IRegister, ref: any) => {
  useEffect(() => {
    const currentDomain = window.location.hostname
    let domainName = currentDomain.split('.')[0]

    if (domainName === 'www' || domainName === 'test' || domainName === 'pp') {
      domainName = currentDomain.split('.')[1]
    }

    fetch(getAssetJsonUrl(`country-list/phone-codes.json`))
      .then((response) => response.json())
      .then((data: any) => {
        if (domainName === '10exchange') {
          setMobileList(data)
          setCountry(data[1].key)
        } else {
          setMobileList(data)
          setCountry(data[0].key)
        }
      })
  }, [])

  const [mobileList, setMobileList] = useState([])
  const navigate = useNavigate()
  const currentDomain = useSelector((state: any) => state.currentDomain)
  const dispatch = useDispatch()

  const [isReferralAdded, setIsReferralAdded] = useState(false)
  const [registeringInProcess, setRegisteringInProcess] = useState(false)
  const [userName, setUserName] = useState('')
  const [fullName, setFullName] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [country, setCountry] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const whiteLabelId = useSelector(
    (state: any) => state.currentDomain.whitelabelId,
  )
  const registeration = useSelector(
    (state: any) => state.appThemeConfiguration!.registration,
  )

  const moveToWhatsapp = () => {
    if (registeration && registeration.whatsappNumber) {
      window.open(`https://wa.me/${registeration.whatsappNumber}`)
    } else {
      toast.error(_getLocalString('LOC_NO_WHATSAPP_FOUND'))
    }
  }

  const [otp, setOtp] = useState('')

  const stateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    switch (name) {
      case 'mobile':
        setMobile(value)
        break
      case 'userName':
        setUserName(value)
        break
      case 'fullName':
        setFullName(value)
        break
      case 'email':
        setEmail(value)
        break
      case 'password':
        setPassword(value)
        break
      case 'passwordConfirmation':
        setPasswordConfirmation(value)
        break
    }
  }

  const registerUser = async () => {
    try {
      let branchId = localStorage.getItem(HEADERS.branchId)
      setRegisteringInProcess(true)
      let formData = new URLSearchParams()
      formData.append('userName', userName ? userName : mobile)
      formData.append('fullName', fullName ? fullName : mobile)
      formData.append(
        'email',
        email ? email : `${userName ? userName : mobile}@gamil.com`,
      )
      formData.append('otp', otp)
      formData.append('type', 'sms')
      formData.append('password', password)
      formData.append('phone', country + mobile.trim())
      formData.append('whiteLabelId', whiteLabelId)
      branchId && formData.append('branchId', branchId)

      props.userSignUp!(
        formData,
        navigate,
        setIsRegister,
        currentDomain,
        () => {
          setRegisteringInProcess(false)
        },
      )
    } catch (error) {}
  }

  const sendOtp = () => {
    if (!mobile) {
      return toast.error('Mobile Number is Required ')
    }
    setIsLoading(true)
    setIsDisabled(true)
    makePostHttpCallAsync(
      dispatch,
      'api/common/signup/otp',
      currentDomain.type,
      JSON.stringify({
        phone: country + mobile,
        type: 'sms',
        operator:
          props.appThemeConfiguration && props.appThemeConfiguration.logoText
            ? props.appThemeConfiguration.logoText
            : DEFAULT_WEBSITE_NAME,
      }),
      '',
      false,
      '',
      true,
      'OTP SENT',
    )
      .then(() => {
        setTimeout(() => {
          setIsResendEnabled(true)
          setIsDisabled(false)
        }, OTP_RESEND_TIME * 1000)
        setIsLoading(false)
      })
      .catch(() => {
        setIsResendEnabled(false)
        setIsDisabled(false)
        setIsLoading(false)
      })
  }

  const ReferralClicked = () => {
    setIsReferralAdded(!isReferralAdded)
  }

  const setIsLoginScren = () => {
    props.setIsLoginScren(true)
    props.setIsRegister(false)
  }

  const setIsRegister = () => {
    props.setIsLoginScren(false)
    props.setIsRegister(false)
  }

  const updateCountry = (value: string) => {
    setCountry(value)
  }

  const isXsScreen = useMediaQuery('(max-width:600px)')
  const [isResendEnabled, setIsResendEnabled] = useState(false)

  const renderRegisterScreen = () => {
    return (
      <div
        className={`flex flex-col justify-start items-center h-full relative ${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'pt-8 pb-8 px-9' : 'px-9 pb-2'} `}
      >
        {REGISTER_SCREEN_UPDATE[currentDomain.domainName] && (
          <>
            <img
              src={getThemeAssetUrl(props.appThemeConfiguration?.logo || '')}
              style={{ width: '10rem', marginBottom: '1rem' }}
              alt='logo'
            />
          </>
        )}
        <div
          className={`not-italic font-bebas tracking-[0.03em] ${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-black bold' : 'text-[color:var(--fontColor)]'} xs:text-[30px] sm:text-[32px] md:text-[32px] lg:text-[32px] xl:text-[35px] leading-[30px] text-center'`}
        >
          {_getLocalString('LOC_REGISTER')}
        </div>
        <div className=''>
          <div className='w-full bg-[linear-gradient(304.3deg,var(--selected-theme-color)_0%,var(--selected-theme-color2)_100%)] backdrop-blur-[1.5px] mt-4 px-5 py-8 rounded-[10px]'>
            <div className='absolute top-0 left-0'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='240'
                height='1'
                viewBox='0 0 351 1'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M0 0.5C0 0.223858 0.181884 0 0.40625 0H350.594C350.818 0 351 0.223858 351 0.5C351 0.776142 350.818 1 350.594 1H0.40625C0.181884 1 0 0.776142 0 0.5Z'
                  fill='url(#paint0_linear_332_14314)'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_332_14314'
                    x1='0.40625'
                    y1='1.24744'
                    x2='354.25'
                    y2='1.24744'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='white' stopOpacity='0' />
                    <stop offset='0.509721' stopColor='#E9FA20' />
                    <stop offset='0.990815' stopColor='white' stopOpacity='0' />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className='font-normal sm:text-[16px] md:text-[20px] lg:text-[22px] xl:text-[25px] xs:text-[20px] leading-[26px] text-center'>
              {_getLocalString('LOC_CAMEL_CASE_NOW')}{' '}
              <span className='text-[#E9FA20] font-bold'>
                {_getLocalString('LOC_CREATE')}&nbsp;
              </span>
              {_getLocalString('LOC_EASILY_ON_CONTENT')}&nbsp;
              <span className='text-[#E9FA20] font-bold'>
                {_getLocalString('LOC_WHATSAPP')}
              </span>
            </div>
            <div
              onClick={moveToWhatsapp}
              className='score-blinking cursor-pointer relative bg-[#239734] border font-bold shadow-[0px_4px_4px_rgba(148,40,3,0.24)] text-center mt-5 p-2 rounded-[5px] border-solid border-white'
            >
              <div
                className='absolute w-[45px] h-[45px]  -left-[9px] -top-[4px]'
                style={{
                  backgroundImage: `url(${getAssetUrl('asset/whatsapp.webp')})`,
                }}
              />
              <span className='sm:text-[14px] md:text-[16px]'>
                {_getLocalString('LOC_FOR_INSTANT_ID')}
              </span>
            </div>
            <div className='absolute bottom-0 left-0'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='240'
                height='1'
                viewBox='0 0 351 1'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M0 0.5C0 0.223858 0.181884 0 0.40625 0H350.594C350.818 0 351 0.223858 351 0.5C351 0.776142 350.818 1 350.594 1H0.40625C0.181884 1 0 0.776142 0 0.5Z'
                  fill='url(#paint0_linear_332_14314)'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_332_14314'
                    x1='0.40625'
                    y1='1.24744'
                    x2='354.25'
                    y2='1.24744'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='white' stopOpacity='0' />
                    <stop offset='0.509721' stopColor='#E9FA20' />
                    <stop offset='0.990815' stopColor='white' stopOpacity='0' />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className='w-full flex font-primary text-base font-bold  tracking-[0.4px] text-white bg-[linear-gradient(304.3deg,rgba(255,156,64,0.94)_0%,rgba(236,28,36,0.94)_100%)] backdrop-blur-[1.5px] mt-2 p-2 rounded-[10px] justify-center items-center gap-2'>
            <div className='absolute top-0 left-0'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='240'
                height='1'
                viewBox='0 0 351 1'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M0 0.5C0 0.223858 0.181884 0 0.40625 0H350.594C350.818 0 351 0.223858 351 0.5C351 0.776142 350.818 1 350.594 1H0.40625C0.181884 1 0 0.776142 0 0.5Z'
                  fill='url(#paint0_linear_332_14314)'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_332_14314'
                    x1='0.40625'
                    y1='1.24744'
                    x2='354.25'
                    y2='1.24744'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='white' stopOpacity='0' />
                    <stop offset='0.509721' stopColor='#E9FA20' />
                    <stop offset='0.990815' stopColor='white' stopOpacity='0' />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='17'
              viewBox='0 0 20 17'
              fill='none'
            >
              <path
                d='M14.9762 17C14.8208 17 14.6653 16.9409 14.5471 16.8219L10.7019 12.9767C10.5287 12.8034 10.476 12.542 10.57 12.3153C10.6639 12.0886 10.8857 11.9405 11.131 11.9405H12.9524V8.29762C12.9524 7.73986 13.4065 7.28571 13.9643 7.28571H15.9881C16.5459 7.28571 17 7.73986 17 8.29762V11.9405H18.8214C19.0667 11.9405 19.2885 12.0886 19.3824 12.3153C19.4763 12.542 19.4245 12.8034 19.2505 12.9767L15.4052 16.8219C15.287 16.9409 15.1316 17 14.9762 17Z'
                fill='white'
              />
              <path
                d='M17 0H2.42857C1.09286 0 0 1.09286 0 2.42857V12.1429C0 13.4786 1.09286 14.5714 2.42857 14.5714H10.0138L9.56048 14.1181C9.22857 13.7943 9.02619 13.3895 8.94524 12.9524H2.42857C1.98333 12.9524 1.61905 12.5881 1.61905 12.1429V2.42857C1.61905 1.98333 1.98333 1.61905 2.42857 1.61905H17C17.4452 1.61905 17.8095 1.98333 17.8095 2.42857V6.39524C18.3033 6.88095 18.619 7.55286 18.619 8.29762V10.3214H18.8214C19.0319 10.3214 19.2343 10.3538 19.4286 10.4105V2.42857C19.4286 1.09286 18.3357 0 17 0Z'
                fill='white'
              />
              <path
                d='M5.86905 4.04762H1.0119C0.676762 4.04762 0.404762 4.31962 0.404762 4.65476V9.91667C0.404762 10.2518 0.676762 10.5238 1.0119 10.5238H5.86905C7.0971 10.5238 8.09524 9.52567 8.09524 8.29762V6.27381C8.09524 5.04576 7.0971 4.04762 5.86905 4.04762ZM4.85714 8.90476C3.96262 8.90476 3.2381 8.18024 3.2381 7.28571C3.2381 6.39119 3.96262 5.66667 4.85714 5.66667C5.75167 5.66667 6.47619 6.39119 6.47619 7.28571C6.47619 8.18024 5.75167 8.90476 4.85714 8.90476Z'
                fill='white'
              />
            </svg>
            {_getLocalString('LOC_SELF_DEPOSIT_AND_WITHDRAW')}
            <div className='absolute bottom-0 left-0'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='240'
                height='1'
                viewBox='0 0 351 1'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M0 0.5C0 0.223858 0.181884 0 0.40625 0H350.594C350.818 0 351 0.223858 351 0.5C351 0.776142 350.818 1 350.594 1H0.40625C0.181884 1 0 0.776142 0 0.5Z'
                  fill='url(#paint0_linear_332_14314)'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_332_14314'
                    x1='0.40625'
                    y1='1.24744'
                    x2='354.25'
                    y2='1.24744'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='white' stopOpacity='0' />
                    <stop offset='0.509721' stopColor='#E9FA20' />
                    <stop offset='0.990815' stopColor='white' stopOpacity='0' />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div
          style={{ minHeight: '10rem' }}
          className='flex flex-col items-center w-full !overflow-y-scroll overflow-x-hidden scrollbar_register pr-2 mt-2'
        >
          <div
            className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-box-black-placeholder ' : 'text-box-white-placeholder '} flex justify-between flex-row-reverse items-center w-full relative mt-2 !gap-2'`}
          >
            <input
              className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-black text-box-black-placeholder !w-full bg-white/[.15] border border-solid border-black/[.21] ' : 'bg-white/[.15] border border-solid border-white/[.21] stext-box-white-placeholder !w-full'} ml-2 textBox rounded-[5px] font-light leading-[19px] text-base tracking-[0.7px]  p-3  focus:outline-none mb-0 '`}
              type='string'
              autoComplete='off'
              value={mobile}
              name='mobile'
              onChange={stateChangeHandler}
              placeholder={`Enter Your Mobile no.`}
            />

            <DropDownGlobal
              className={`text-box-white-placeholder border border-solid ${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? '' : 'border-white/[.21] bg-white/[.15]'} !font-light !tracking-[0.7px] !py-[3px] !h-[45px] !w-fit`}
              textClass='!text-base font-bold'
              backgroundColor='!bg-[rgba(196,39,29,1)]'
              item={mobileList}
              selectedValue={country}
              update={updateCountry}
              blackText={true}
              isRegisterDropDown={true}
            />
          </div>

          <div className='w-full flex justify-between items-center mt-2'>
            <div
              onClick={() => !isDisabled && sendOtp()}
              className={`cursor-pointer flex items-center justify-center text-base ${isLoading ? '' : isDisabled ? 'bg-[grey]' : 'bg-[linear-gradient(43.38deg,var(--button-popup-1)_-13.67%,var(--button-popup-2)_84.99%)]'} xs:w-[34%] sm:w-[49%] md:w-[49%] px-[0.4rem] p-4 lg:p-[0.65rem] rounded-[5px]`}
            >
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '2px',
                  }}
                >
                  <CircularProgress className='white-circular' />
                </div>
              ) : (
                <>
                  {_getLocalString(
                    isResendEnabled ? 'LOC_RESEND' : 'LOC_SEND_OTP',
                  )}
                  {isDisabled && (
                    <RenderReverseNumber startWith={OTP_RESEND_TIME} />
                  )}
                </>
              )}
            </div>
            <input
              className={`text-box-white-placeholder textBox xs:w-[37%] ${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-box-black-placeholder text-black bg-black/[.15] border border-solid border-black/[.21] ' : 'bg-white/[.15] border border-solid border-white/[.21] '} sm:w-[40%] md:w-[49%] rounded-[5px] relative font-light text-base tracking-[0.7px] ml-[0] p-3 focus:outline-none leading-[19px]`}
              type='text'
              autoComplete='off'
              value={otp}
              onChange={(event) => setOtp(event.target.value)}
              placeholder={_getLocalString('LOC_ENTER_OTP')}
            />
          </div>
          <form className='w-full'>
            <>
              <div className='flex justify-center items-center relative w-full mt-2'>
                <div className='absolute left-4 self-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 12 12'
                    fill='none'
                  >
                    <path
                      d='M9.49998 4.212V3.49999C9.49998 1.56701 7.933 0 5.99999 0C4.06698 0 2.5 1.56701 2.5 3.49999V4.212C1.59002 4.60915 1.00131 5.50713 1 6.49999V9.49999C1.00164 10.88 2.11996 11.9984 3.49998 12H8.49998C9.88 11.9984 10.9983 10.88 11 9.49999V6.49999C10.9987 5.50713 10.41 4.60915 9.49998 4.212ZM6.49998 8.50001C6.49998 8.77615 6.27613 9 5.99999 9C5.72385 9 5.5 8.77615 5.5 8.50001V7.5C5.5 7.22386 5.72385 7.00001 5.99999 7.00001C6.27613 7.00001 6.49998 7.22386 6.49998 7.5V8.50001ZM8.5 4.00001H3.49998V3.50002C3.49998 2.11931 4.61927 1.00001 5.99999 1.00001C7.38072 1.00001 8.5 2.11929 8.5 3.50002V4.00001Z'
                      fill={
                        REGISTER_SCREEN_UPDATE[currentDomain.domainName]
                          ? 'black'
                          : 'white'
                      }
                    />
                  </svg>
                </div>
                <input
                  className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-black text-box-black-placeholder  bg-black/[.15] border-black/[.21]' : 'text-box-white-placeholder  bg-white/[.15] border-white/[.21]'} textBox w-full rounded-[5px] font-light leading-[19px] text-base tracking-[0.7px] ml-[0] p-3 border border-solid focus:outline-none pl-12`}
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder={_getLocalString('LOC_PASSWORD_LABEL')}
                  value={password}
                  id='register-password'
                  autoComplete='off'
                  onChange={stateChangeHandler}
                  name='password'
                />
                {isPasswordVisible ? (
                  <VisibilityIcon
                    className=' cursor-pointer absolute !text-base right-3 self-center'
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />
                ) : (
                  <VisibilityOffIcon
                    className=' cursor-pointer absolute !text-base right-3 self-center'
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />
                )}
              </div>
              {password && (
                <div
                  className={
                    REGISTER_SCREEN_UPDATE[currentDomain.domainName]
                      ? 'text-black'
                      : ''
                  }
                >
                  {checkPasswordVerification(password)}
                </div>
              )}

              <div className='flex justify-center items-center relative w-full mt-2'>
                <div className='absolute left-4 self-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 12 12'
                    fill='none'
                  >
                    <path
                      d='M9.49998 4.212V3.49999C9.49998 1.56701 7.933 0 5.99999 0C4.06698 0 2.5 1.56701 2.5 3.49999V4.212C1.59002 4.60915 1.00131 5.50713 1 6.49999V9.49999C1.00164 10.88 2.11996 11.9984 3.49998 12H8.49998C9.88 11.9984 10.9983 10.88 11 9.49999V6.49999C10.9987 5.50713 10.41 4.60915 9.49998 4.212ZM6.49998 8.50001C6.49998 8.77615 6.27613 9 5.99999 9C5.72385 9 5.5 8.77615 5.5 8.50001V7.5C5.5 7.22386 5.72385 7.00001 5.99999 7.00001C6.27613 7.00001 6.49998 7.22386 6.49998 7.5V8.50001ZM8.5 4.00001H3.49998V3.50002C3.49998 2.11931 4.61927 1.00001 5.99999 1.00001C7.38072 1.00001 8.5 2.11929 8.5 3.50002V4.00001Z'
                      fill={
                        REGISTER_SCREEN_UPDATE[currentDomain.domainName]
                          ? 'black'
                          : 'white'
                      }
                    />
                  </svg>
                </div>
                <input
                  className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-box-black-placeholder' : 'text-box-white-placeholder'} textBox w-full rounded-[5px] font-light leading-[19px] text-base tracking-[0.7px] ml-[0] p-3 bg-white/[.15] border border-solid border-white/[.21] focus:outline-none pl-12`}
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  placeholder={_getLocalString('LOC_CONFIRM_PASSWORD')}
                  value={passwordConfirmation}
                  id='register-confirm-password'
                  autoComplete='off'
                  onChange={stateChangeHandler}
                  name='passwordConfirmation'
                />
                {isConfirmPasswordVisible ? (
                  <VisibilityIcon
                    className=' cursor-pointer !text-base absolute right-3 self-center'
                    onClick={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  />
                ) : (
                  <VisibilityOffIcon
                    className='cursor-pointer absolute !text-base right-3 self-center'
                    onClick={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  />
                )}
              </div>

              <div
                className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-black bold' : 'text-box-white-placeholder'} flex justify-start items-center relative w-full mt-2`}
              >
                <span>
                  {_getLocalString('LOC_HAVE_REFERRAL_CODE')}?{' '}
                  <u onClick={ReferralClicked} className='cursor-pointer'>
                    {!isReferralAdded
                      ? _getLocalString('LOC_YES')
                      : _getLocalString('LOC_NO')}
                  </u>
                </span>
              </div>

              {isReferralAdded && (
                <div className='flex justify-center items-center relative w-full mt-2'>
                  <div className='absolute left-4 self-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      viewBox='0 0 12 12'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_310_34681)'>
                        <path
                          d='M3.75 6.5C3.30499 6.5 2.86998 6.36804 2.49997 6.12081C2.12996 5.87358 1.84157 5.52217 1.67127 5.11104C1.50097 4.69991 1.45642 4.24751 1.54323 3.81105C1.63005 3.37459 1.84434 2.97368 2.15901 2.65901C2.47368 2.34434 2.87459 2.13005 3.31105 2.04323C3.7475 1.95642 4.1999 2.00098 4.61104 2.17127C5.02217 2.34157 5.37357 2.62996 5.62081 2.99997C5.86804 3.36998 6 3.80499 6 4.25C5.99934 4.84654 5.76207 5.41845 5.34026 5.84026C4.91845 6.26207 4.34653 6.49934 3.75 6.5ZM7 12H0.5C0.367392 12 0.240215 11.9473 0.146447 11.8536C0.0526784 11.7598 0 11.6326 0 11.5V11.25C0 10.2554 0.395088 9.30161 1.09835 8.59835C1.80161 7.89509 2.75544 7.5 3.75 7.5C4.74456 7.5 5.69839 7.89509 6.40165 8.59835C7.10491 9.30161 7.5 10.2554 7.5 11.25V11.5C7.5 11.6326 7.44732 11.7598 7.35355 11.8536C7.25979 11.9473 7.13261 12 7 12ZM8.75 4.5C8.30499 4.5 7.86998 4.36804 7.49997 4.12081C7.12996 3.87357 6.84157 3.52217 6.67127 3.11104C6.50097 2.69991 6.45642 2.24751 6.54323 1.81105C6.63005 1.37459 6.84434 0.973679 7.15901 0.659011C7.47368 0.344343 7.87459 0.130051 8.31105 0.0432341C8.7475 -0.0435826 9.1999 0.000974894 9.61104 0.171272C10.0222 0.341569 10.3736 0.629957 10.6208 0.999968C10.868 1.36998 11 1.80499 11 2.25C10.9993 2.84654 10.7621 3.41845 10.3403 3.84026C9.91845 4.26207 9.34653 4.49934 8.75 4.5ZM8.0395 5.5105C7.57381 5.57298 7.12605 5.73095 6.72425 5.97453C6.32246 6.2181 5.97529 6.54202 5.7045 6.926C6.82495 7.43483 7.70531 8.35711 8.1615 9.5H11.5C11.6326 9.5 11.7598 9.44732 11.8536 9.35356C11.9473 9.25979 12 9.13261 12 9V8.981C11.9995 8.4826 11.8926 7.99005 11.6866 7.53623C11.4806 7.0824 11.1802 6.67775 10.8053 6.34928C10.4305 6.02081 9.98986 5.77609 9.51291 5.63144C9.03597 5.4868 8.53365 5.44557 8.0395 5.5105Z'
                          fill='white'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_310_34681'>
                          <rect
                            width='16'
                            height='16'
                            fill={
                              REGISTER_SCREEN_UPDATE[currentDomain.domainName]
                                ? 'black'
                                : 'white'
                            }
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <input
                    className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-box-black-placeholder bold' : 'text-box-white-placeholder'} textBox w-full rounded-[5px] font-light leading-[19px] text-base tracking-[0.7px] ml-[0] p-3 bg-white/[.15] border border-solid border-white/[.21] focus:outline-none pl-12'`}
                    type='text'
                    autoComplete='off'
                    placeholder={_getLocalString('LOC_REFERRAL_CODE')}
                  />
                </div>
              )}

              <div className='flex w-full justify-between items-center flex-wrap mx-2 mt-2'>
                <FormControlLabel
                  className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-black bold' : 'text-box-white-placeholder'}  ml-0 text-[color:var(--fontColor)] md:text-xs text-sm !m-0'`}
                  control={
                    <Checkbox
                      defaultChecked
                      id='register-remeber-me'
                      className={
                        REGISTER_SCREEN_UPDATE[currentDomain.domainName]
                          ? '!text-black'
                          : '!text-white'
                      }
                      icon={
                        <CheckBoxOutlineBlankIcon
                          className={
                            REGISTER_SCREEN_UPDATE[currentDomain.domainName]
                              ? '!text-black'
                              : '!text-white'
                          }
                        />
                      }
                    />
                  }
                  label={_getLocalString('LOC_I_M_18')}
                />
                <FormControlLabel
                  className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-black bold' : 'text-box-white-placeholder'}  ml-0 text-[color:var(--fontColor)] md:text-xs text-sm !m-0'`}
                  control={
                    <Checkbox
                      id='register-accept-term'
                      defaultChecked
                      className={
                        REGISTER_SCREEN_UPDATE[currentDomain.domainName]
                          ? '!text-black'
                          : '!text-white'
                      }
                      icon={
                        <CheckBoxOutlineBlankIcon
                          className={
                            REGISTER_SCREEN_UPDATE[currentDomain.domainName]
                              ? '!text-black'
                              : '!text-white'
                          }
                        />
                      }
                    />
                  }
                  label={
                    <span
                      onClick={(e) => {
                        props.setIsTermsAndConditions(true)
                        e.stopPropagation()
                      }}
                    >
                      {_getLocalString('LOC_I_ACCEPT_ALL_TERMS')}
                    </span>
                  }
                />
              </div>
            </>
          </form>
        </div>
        <div className='w-full mt-2'>
          <div
            className='bg-[linear-gradient(43.38deg,var(--button-popup-1)_-13.67%,var(--button-popup-2)_84.99%)] mt-2 border shadow-[0px_4px_4px_rgba(148,40,3,0.24)] w-full text-[color:var(--fontColor)] font-bold text-center cursor-pointer leading-[19px] p-3 tracking-[0.8px] rounded-[5px] text-lg border-solid border-[var(--button-popup-border)]'
            onClick={registerUser}
          >
            {registeringInProcess ? (
              <CircularProgress size={25} className='!text-white' />
            ) : (
              _getLocalString('LOC_REGISTER')
            )}
          </div>
          {REGISTER_SCREEN_UPDATE[currentDomain.domainName] && (
            <div
              className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] && 'text-black bold'} text-center !font-light !tracking-[0.5px]  text-md leading-[19px] mt-4 mb-10'`}
            >
              {_getLocalString('LOC_ALREADY_MEMBER')}?
              <span
                onClick={() => setIsLoginScren()}
                className='ml-1 font-bold  underline underline-offset-4 cursor-pointer'
              >
                {_getLocalString('LOC_LOGIN_SMALL_CASE')}
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      tabIndex={-1}
      ref={ref}
      id='normalFont'
      className='relative w-[90%] md:w-[70%] h-[600px]  p-[1.5px] sm:justify-center lg:w-[60%] xl:w-[50%] rounded-[10px]  bg-[linear-gradient(180deg,var(--popup-box-1),var(--paymentMethodColor)),linear-gradient(104.51deg,var(--popup-box-3),var(--popup-box-4))]'
    >
      <div
        className='w-full h-full bg-no-repeat bg-cover  rounded-[10px]'
        style={{
          backgroundImage: `url(${getAssetUrl('assets/stadium-lights-flashes-3d.webp')})`,
        }}
      >
        <div className='flex bg-no-repeat bg-cover bg-left rounded-[10px] h-full'>
          <div className='relative w-1/2 bg-cover bg-center  rounded-l-[10px]  hidden sm:flex'>
            <div
              className='w-full h-full absolute bg-cover rounded-l-[10px] bg-no-repeat mix-blend-darken '
              style={{
                backgroundImage: `url(${getAssetUrl('light.webp')})`,
              }}
            ></div>
            <div
              className='w-full h-full absolute bg-top-left  bg-[size:150%] bg-[position:-150px_-5px] bg-no-repeat  mix-blend-color-dodge'
              style={{
                backgroundImage: `url(${getAssetUrl('asset/girl-bg.webp')})`,
                transform: 'rotate(10deg)',
              }}
            ></div>
          </div>
          <div
            className={`bg-[size:108%] bg-[position:-205px_-70px] w-full h-full absolute top-0 left-0 bg-no-repeat ${isXsScreen ? 'hidden' : ''}`}
            style={{
              backgroundImage: `url(${getAssetUrl('asset/girl.webp')})`,
            }}
          ></div>

          <div
            style={{ borderRadius: '0.5rem' }}
            className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'bg-white' : 'bg-gradient-to-br pb-4 to-[var(--popup-box-right-1)] to-[60%]  from-[var(--popup-box-right-2)]'} rounded-r-lg bg-center bg-no-repeat  bg-cover w-full xs:w-[100%] justify-center sm:w-1/2`}
          >
            <div
              className={`${REGISTER_SCREEN_UPDATE[currentDomain.domainName] ? 'text-black' : ''} absolute z-[999] top-4 right-5 cursor-pointer`}
            >
              <DisabledByDefaultIcon
                onClick={setIsRegister}
                className='black'
              />
            </div>

            {renderRegisterScreen()}
          </div>
        </div>
      </div>
    </div>
  )
})
